import { capitalise, isNotEmpty } from "@wisr/common";
import dayjs from "dayjs";
import {
  ScoresEventData,
  SingleScoreEventData,
} from "../../../types/analytics";
import { ScoreProviders } from "../../../types/credit";
import { formatInsightsData } from "../insights/insights-credit.utils";
import {
  ContributingFactorDetails,
  CreditScore,
  CreditScoreResponse,
  RunDetails,
} from "../../../types/score-credit-widget";
import { HAS_SCORE, SCORE_PROVIDER } from "./score-credit-widget.consts";

export const getLatestDate = (runDetails: RunDetails[], previous?: boolean) => {
  const orderedDetails = [...runDetails].sort((a, b) => {
    const firstDate = dayjs(a.runDate);
    const secondDate = dayjs(b.runDate);
    if (firstDate.isBefore(secondDate)) {
      // If date is before push it back
      return 1;
    }
    if (firstDate.isAfter(secondDate)) {
      // If date is after bring it forward
      return -1;
    }
    return 0;
  });
  if (previous && orderedDetails.length > 1) {
    return orderedDetails[1].runDate;
  }
  return orderedDetails[0]?.runDate || "";
};

export const getInsightsByProvider = (
  insights: CreditScore[] | undefined,
  provider: ScoreProviders
) => {
  return insights?.find((insight) => insight.provider === provider);
};

export const getScoreByProvider = (
  scores: CreditScoreResponse | undefined,
  provider: ScoreProviders
) => {
  return scores?.creditScoreSummaryList?.find(
    (summary) => summary.provider == provider
  );
};

export const getSelectedScore = (score: CreditScore) => {
  return score.oneScoreComprehensive && score.oneScoreComprehensive !== 0
    ? score.oneScoreComprehensive
    : score.score;
};

export const getScoreState = (deltaScore: number) => {
  // Test implicitly covered
  if (deltaScore === 0) {
    return "neutral";
  }
  return deltaScore > 0 ? "positive" : "negative";
};

export const getScoresEventData = (
  creditScores: CreditScore[],
  isIdMatrixPass: boolean,
  newKcfActivity: boolean
): ScoresEventData => {
  const equifaxItem = creditScores.filter(
    (item) => item.provider === SCORE_PROVIDER.EQUIFAX
  )[0];
  const experianItem = creditScores.filter(
    (item) => item.provider === SCORE_PROVIDER.EXPERIAN
  )[0];
  return {
    "Equifax score": getScoreText(equifaxItem),
    "Experian score": getScoreText(experianItem),
    "Id matrix passed": isIdMatrixPass.toString(),
    "New kcf activity": newKcfActivity.toString(),
  };
};

export const getSingleScoreEventData = (
  provider: ScoreProviders,
  creditScore: CreditScore,
  newActivityCount: number
): SingleScoreEventData => {
  const { totalEnquiries, paymentsOnTime, totalAccounts, fileAge } =
    formatInsightsData(creditScore);
  const capitalProvider = capitalise(provider);
  const providerScore = `${capitalProvider} score`;

  let analyticsData = {
    Provider: capitalProvider,
    [providerScore]: getScoreText(creditScore),
  };
  if (creditScore.creditScoreDisplay !== HAS_SCORE) {
    analyticsData = {
      ...analyticsData,
      "Credit enquiries": "N/A",
      "Total accounts": "N/A",
      "Payments on time": "N/A",
      "Age of file": "N/A",
      "New kcf count": "N/A",
    };
  } else {
    analyticsData = {
      ...analyticsData,
      "Credit enquiries": totalEnquiries,
      "Total accounts": totalAccounts,
      "Payments on time": paymentsOnTime,
      "Age of file": fileAge,
      "New kcf count":
        provider === SCORE_PROVIDER.EQUIFAX ? newActivityCount : "N/A",
    };
  }

  return analyticsData;
};

export const getScoreText = (creditScore: CreditScore) => {
  if (creditScore.creditScoreDisplay === HAS_SCORE) {
    return getSelectedScore(creditScore);
  }
  return creditScore.creditScoreDisplay;
};

export const getNewActivityCount = (
  contributingFactors?: ContributingFactorDetails[]
) => {
  if (contributingFactors && isNotEmpty(contributingFactors)) {
    return contributingFactors.filter((factor) => {
      return factor.isNew;
    }).length;
  }
  return 0;
};
