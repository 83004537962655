import { monthsToYears } from "@wisr/common";
import { tryFormatPaymentHistory } from "../../../shared/credit/credit.utils";
import { CreditScore } from "../../../types/score-credit-widget";

export const formatInsightsData = (insights?: CreditScore) => {
  const totalEnquiries = insights?.numberOfEnquiries ?? 0;
  const totalAccounts = insights?.numberOfAccounts ?? 0;
  const paymentsOnTime = tryFormatPaymentHistory(insights?.paymentHistory);
  const fileAge = monthsToYears(insights?.ageOfFileInMonths ?? 0, true);
  return {
    totalEnquiries,
    totalAccounts,
    paymentsOnTime,
    fileAge,
  };
};
