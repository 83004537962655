import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isNotEmpty } from "../../../shared/common.utils";
import { AppStore } from "../../../store";
import {
  ContributingFactorDetails,
  CreditScoreResponse,
  MoneyMoment,
  RunDetails,
  ScoreCreditWidgetState,
} from "../../../types/score-credit-widget";
import { scoreCreditGetAction } from "./score-credit-widget.actions";
import { getNewActivityCount } from "./score-credit-widget.utils";
import { refiOfferCodes } from "./score-credit-widget.consts";

export const useGetScores = () => {
  return useSelector<AppStore, ScoreCreditWidgetState | undefined>(
    (store) => store.scoreCreditWidgetState
  );
};

export const useIdChecksSuccessful = () => {
  const [idmResult, setIdmResult] = React.useState(true);

  const creditScores = useGetScores();

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!isNotEmpty(creditScores)) {
      dispatch(scoreCreditGetAction());
    }
  }, [dispatch, creditScores]);

  React.useEffect(() => {
    if (creditScores?.creditScoreSummaryList) {
      const idCheckResult = creditScores.creditScoreSummaryList.every(
        (score) => score.idCheckSuccessful
      );
      setIdmResult(idCheckResult);
    } else {
      setIdmResult(false);
    }
  }, [creditScores]);

  return idmResult;
};

export const useScoreProcessing = (
  creditScores: CreditScoreResponse | undefined
) => {
  const [scoreProcessing, setScoreProcessing] =
    React.useState<ScoreProcessingType>({
      status: false,
      attempt: 0,
    });

  React.useEffect(() => {
    if (
      creditScores?.creditScoreSummaryList &&
      creditScores.creditScoreSummaryList.length > 0
    ) {
      const runDetails: RunDetails[][] =
        creditScores.creditScoreSummaryList.map((score) => score.runDetails);
      const processing = runDetails.some((run) =>
        run.some((detail) =>
          CREDIT_SCORE_IN_PROGRESS_STATES.includes(detail.runStatus)
        )
      );
      setScoreProcessing({
        status: processing,
        attempt: scoreProcessing.attempt + 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditScores]);

  return scoreProcessing;
};

export interface ScoreProcessingType {
  status: boolean;
  attempt: number;
}

const CREDIT_SCORE_IN_PROGRESS_STATES = [
  "Queued",
  "Processing",
  "Scored",
  "Background Scored",
];

export const useGetContributingFactors = () => {
  return useSelector<AppStore, ContributingFactorDetails[]>(
    (store) => store.scoreCreditWidgetState.contributingFactors ?? []
  );
};

export const useGetNewActivityCount = () => {
  return useSelector<AppStore, number>((store) =>
    getNewActivityCount(store.scoreCreditWidgetState.contributingFactors)
  );
};

export const useGetEstimateEligible = () => {
  return useSelector<AppStore, boolean>(
    (store) => !!store.scoreCreditWidgetState.rateEstimateOffers?.moneyMoment
  );
};

export const useGetDrivaEligible = () => {
  return useSelector<AppStore, boolean>(
    (store) =>
      !!store.scoreCreditWidgetState.rateEstimateOffers?.drivaOffer.eligible ===
      true
  );
};

export const useGetRefiEligible = () => {
  return useSelector<AppStore, boolean>(
    (store) =>
      !!store.scoreCreditWidgetState.rateEstimateOffers?.moneyMoment &&
      !!store.scoreCreditWidgetState.rateEstimateOffers.moneyMoment
        ?.offerCode &&
      refiOfferCodes.includes(
        store.scoreCreditWidgetState.rateEstimateOffers.moneyMoment.offerCode
      )
  );
};

export const useGetMoneyMoment = () => {
  return useSelector<AppStore, MoneyMoment | null | undefined>(
    (store) => store.scoreCreditWidgetState.rateEstimateOffers?.moneyMoment
  );
};
