import { ScoreProviders, ScoreText } from "../../../types/credit";
import { GaugeBands } from "../../../types/graph";

export const SCORE_TEXT: { [key: string]: ScoreText } = {
  NOMATCH: "No match",
  NORECORD: "No record",
};

export const HAS_SCORE: ScoreText = "Has credit score";

export const SCORE_DESCRIPTION = {
  NOMATCH: "No match",
  NORECORD: "No record with bureau",
};

export const SCORE_PROVIDER: { [key: string]: ScoreProviders } = {
  EQUIFAX: "equifax",
  EXPERIAN: "experian",
};

export const belowAverageColour = "#D37458", // brand red
  averageColour = "#E3B6CE", // brand pink
  goodColour = "#D68B4B", // brand orange
  veryGoodColour = "#F5DE66", // brand yellow
  excellentColour = "#9EB964"; // brand green

export const experianColour = "#258184",
  equifaxColour = "#15384f";

export const equifaxBands: GaugeBands[] = [
  {
    bandColour: belowAverageColour,
    bandLimit: 509,
    bandTitle: "below average",
  },
  {
    bandColour: averageColour,
    bandLimit: 621,
    bandTitle: "average",
  },
  {
    bandColour: goodColour,
    bandLimit: 725,
    bandTitle: "good",
  },
  {
    bandColour: veryGoodColour,
    bandLimit: 832,
    bandTitle: "very good",
  },
  {
    bandColour: excellentColour,
    bandLimit: 1200,
    bandTitle: "excellent",
  },
];

export const equifaxOneBands: GaugeBands[] = [
  {
    bandColour: belowAverageColour,
    bandLimit: 459,
    bandTitle: "below average",
  },
  {
    bandColour: averageColour,
    bandLimit: 660,
    bandTitle: "average",
  },
  {
    bandColour: goodColour,
    bandLimit: 734,
    bandTitle: "good",
  },
  {
    bandColour: veryGoodColour,
    bandLimit: 852,
    bandTitle: "very good",
  },
  {
    bandColour: excellentColour,
    bandLimit: 1200,
    bandTitle: "excellent",
  },
];

export const experianBands: GaugeBands[] = [
  {
    bandColour: belowAverageColour,
    bandLimit: 549,
    bandTitle: "below average",
  },
  {
    bandColour: averageColour,
    bandLimit: 624,
    bandTitle: "average",
  },
  {
    bandColour: goodColour,
    bandLimit: 699,
    bandTitle: "good",
  },
  {
    bandColour: veryGoodColour,
    bandLimit: 799,
    bandTitle: "very good",
  },
  {
    bandColour: excellentColour,
    bandLimit: 1000,
    bandTitle: "excellent",
  },
];

export const gaugeColours = {
  equifax: "#15384f",
  experian: "#7ac2bb",
};

export const refiOfferCodes = [
  "PL_Non-Big 4_best deal",
  "PL_Non-Big 4_beat your bank",
  "PL_Non-Big 4_smarter",
  "PL_Non-Big 4_ditch",
  "PL_Non-Big 4_pocket",
  "PL_Non-Big 4_sky-high",
  "PL_Non-Big 4_better deal",
  "PL_Non-Big 4_money pocket",
  "Secured_Non-Big 4_pay off sooner",
  "Secured_Non-Big 4_drive home",
  "Secured_Non-Big 4_money pocket pocket",
  "Secured_Non-Big 4_better deal",
];
