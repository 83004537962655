import dayjs from "dayjs";
import { CreditHistory } from "../../types/credit";

export interface Month {
  monthValue: number;
  monthName: {
    short: string;
    long: string;
    full: string;
  };
}

export interface CreditScore {
  date: string;
  score: number | null;
}

const listMonth: Month[] = [
  {
    monthValue: 1,
    monthName: {
      short: "J",
      long: "Jan",
      full: "January",
    },
  },
  {
    monthValue: 2,
    monthName: {
      short: "F",
      long: "Feb",
      full: "February",
    },
  },
  {
    monthValue: 3,
    monthName: {
      short: "M",
      long: "Mar",
      full: "March",
    },
  },
  {
    monthValue: 4,
    monthName: {
      short: "A",
      long: "Apr",
      full: "April",
    },
  },
  {
    monthValue: 5,
    monthName: {
      short: "M",
      long: "May",
      full: "May",
    },
  },
  {
    monthValue: 6,
    monthName: {
      short: "J",
      long: "Jun",
      full: "June",
    },
  },
  {
    monthValue: 7,
    monthName: {
      short: "J",
      long: "Jul",
      full: "July",
    },
  },
  {
    monthValue: 8,
    monthName: {
      short: "A",
      long: "Aug",
      full: "August",
    },
  },
  {
    monthValue: 9,
    monthName: {
      short: "S",
      long: "Sep",
      full: "September",
    },
  },
  {
    monthValue: 10,
    monthName: {
      short: "O",
      long: "Oct",
      full: "October",
    },
  },
  {
    monthValue: 11,
    monthName: {
      short: "N",
      long: "Nov",
      full: "November",
    },
  },
  {
    monthValue: 12,
    monthName: {
      short: "D",
      long: "Dec",
      full: "December",
    },
  },
];

export const filterField = (list: CreditHistory[], par: string) =>
  list.filter(
    (el: CreditHistory) => el.provider.toLowerCase() === par.toLowerCase()
  );

export const attachLabelMonthYear = (listCredit: CreditHistory[]) => {
  return listCredit.map((item: CreditHistory) => {
    let month: number;
    let year: number;
    if (item.scoreDate) {
      month = dayjs(item.scoreDate).toDate().getMonth() + 1;
      year = dayjs(item.scoreDate).toDate().getFullYear();
    } else {
      month = dayjs(new Date()).toDate().getUTCMonth() + 1;
      year = dayjs(new Date()).toDate().getFullYear();
    }
    return {
      date: `${
        listMonth.find((el: Month) => el.monthValue === month)?.monthName.full
      } ${year}`,
      score: item.score || null,
    } as CreditScore;
  });
};
export const generateMonthFollowList = (equifax: CreditHistory[]) => {
  return equifax
    .map((el: CreditHistory) => {
      const month =
        dayjs(el.scoreDate || "")
          .toDate()
          .getMonth() + 1;
      return listMonth.find((el2: Month) => el2.monthValue === month);
    })
    .filter((item: Month | undefined): item is Month => item !== undefined);
};

export const removeNegative = (dataArray: CreditScore[]) => {
  return dataArray.map((item: CreditScore) => {
    let value = item.score;
    if (value === null || value < 0) {
      value = 0;
    }
    return { ...item, score: value };
  });
};

export const tryFormatPaymentHistory = (num?: number | null): string => {
  const isValid =
    num !== undefined && num !== null && !isNaN(num) && num >= 0 && num <= 100;
  return isValid ? `${num}%` : "n/a";
};
